import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { rhythm } from '../../utils/typography'
import styled from "@emotion/styled";
import Scene from "../../components/scenes/scene2";

import Mymap from "../../components/maps/mymap";

import Map from "../../components/maps/map-mapbox"

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja24zZmhxODAwOGpoMnZvMGpnZmtrNnhiIn0.62IOjLEwae3VzgbXme8MNg';


const Header = styled('div')
    `
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${rhythm(25)})  {
    flex-direction: column;
    & > * + * {
      margin-top: ${rhythm(0.5)}; 
    }
    div {
      align-items: center !important;
    }
  }
`



const ThirdPage = () => (
  <Layout>
      <script data-goatcounter="https://metrics.goatcounter.com/count"
              async src="//gc.zgo.at/count.js"></script>
    <SEO title="Developing.." />

      <div align={'center'}>
          {/*<h1>Where I used to live in 2021</h1>*/}
            <Map />
      </div>
      {/*<h1>Hi</h1>*/}
      {/*<p>Here will be some projects</p>*/}
      {/*<Link to="/">Go back to the homepage</Link>*/}

      {/*<div style={ {margin: `0 auto`, width:`100%`,  }}>*/}


      {/*</div>*/}
  </Layout>
)

export default ThirdPage
